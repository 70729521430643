@use "src/styles/vars";

.pokemon-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: vars.$colorBgCard;
  min-width: 250px;
  max-width: 90%;
  width: 31vw;
  min-height: 350px;
  height: 35vh;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  & img {
    height: 200px;
    max-width: 90%;
    margin: auto;
  }
}

.pokemon-detail {
  width: 100%;
  padding: 1.5rem 1rem 0.5rem 0.5rem;

  & h3 {
    padding-top: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 1.5rem;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &-first {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    width: 100%;
  }
  &-type {
    margin-top: 2rem;
  }
}

@media only screen and (min-width: 400px) {
  .pokemon-card {
    min-height: 400px;
    & img {
      height: 23vh;
    }
  }
}

@media only screen and (min-width: 800px) {
  .pokemon-card {
    & img {
      height: 23vh;
    }
  }
}
