@use 'src/styles/vars';

.button {
  display: block;
  margin: auto;
  background-color: vars.$colorBgCard;
  border: solid 1px #454545;
  border-radius: 1rem;
  color: vars.$colorFont;
  font-size: 2rem;
  padding: 1rem;

  &-to-top {
    background-color: #4545459c;
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 0.5rem;
  }
}

.pokemon {
  &-filter {
    display: flex;
    justify-content: center;

    &-button {
      font-size: 1rem;
      margin: 0.3rem 0.3rem;
      background-color: #2e2e2e80;
    }
  }
  &-select {
    margin-left: 1rem;
    padding: 0.5rem;
    height: 50px;
    min-width: 150px;
    background-color: #c0404058;
    border: solid 1px #f9d2d3;
    border-radius: 5px;
    color: white;
    font-family: inherit;
    text-align: center;
    font-size: 1rem;
  }
}
