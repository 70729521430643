@use 'reset';
@use 'vars';

body {
  margin: 1rem;
  background-color: #c04140;
  color: vars.$colorFont;
  font-size: 20px;
  font-family: 'Bree Serif', serif;
}
