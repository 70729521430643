@use "src/styles/vars";
.type {
  width: 100%;
}
.types-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  & .button {
    width: 30%;
    margin: 0.5rem;
    font-size: 1rem;
  }
}

.types-pokemons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
}
