@use 'src/styles/vars';

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: vars.$colorFontHeader;
  background-color: vars.$colorBgHeader;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  min-height: 4rem;

  &-title {
    font-size: 2rem;
  }
  &-liste-type {
    font-size: 1.6rem;
  }
}
