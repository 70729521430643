@use 'src/styles/vars';

.pokemon-details {
  text-align: center;
  margin-left: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  & img,
  h2 {
    margin: 1rem auto;
    max-width: 80%;
  }

  &--progress {
    width: 100%;
    padding: 0.5rem;
    &-stats {
      margin: 0.5rem 0 0.5rem 0;
    }
  }
}
